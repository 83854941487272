<script>
// Extensions
import View from "@/views/View";

// Mixins
import LoadSections from "@/mixins/load-sections";

export default {
  name: "ResourceAccount",

  metaInfo: { title: "Resource Account Management" },

  extends: View,

  mixins: [LoadSections(["resource/sections/resource-account"])],

  props: {
    id: {
      type: String,
      default: "resourceaccount",
    },
  },
};
</script>
